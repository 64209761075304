<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Agregar ocasión</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Upload -->
        <div class="upload-img mb-5" v-if="!occasion.image">
          <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
          <vs-button @click="$refs.uploadImgInput.click()">Subir imagen</vs-button>
        </div>
        <!--End-->
        <!-- Occasion Image -->
        <template v-if="occasion.image">
          <!-- Image Container -->
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="occasion.image" alt="img" class="responsive">
          </div>

          <!-- Image upload Buttons -->
          <div class="modify-img mb-4 text-center">
            <vs-button type="flat" color="#999" @click="occasion.image=null">Eliminar imagen</vs-button>
          </div>
        </template>
        <!--End-->

        <!-- Name -->
        <vs-input @input="setSlug" data-vv-as="nombre" @blur="occasion.name= trimString(occasion.name)" label="Nombre"
                  v-model="occasion.name"
                  class="w-full" name="name"
                  v-validate="'required|min:3'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <!-- Slug -->
        <vs-input disabled @blur="occasion.slug= trimString(occasion.slug)" label="Slug" v-model="occasion.slug"
                  class="mt-5 w-full" name="slug"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('slug')">{{ errors.first('slug') }}</span>

        <!-- Description -->
        <vs-input data-vv-as="descripción" @blur="occasion.description= trimString(occasion.description)"
                  label="Descripción"
                  v-model="occasion.description" class="mt-5 w-full" name="description"
                  v-validate="'min:5'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('description')">{{ errors.first('description') }}</span>

        <!-- Order -->
        <vs-input data-vv-as="order" label="Orden"
                  v-model.number="occasion.order"
                  class="mt-5 w-full" name="order"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('order')">{{ errors.first('order') }}</span>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import vSelect from 'vue-select'

import { db, auth, storage, FieldValue } from '@/firebase/firebaseConfig'

export default {
  name: 'NewOccasion',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  mixins: [trimString],
  data () {
    return {
      occasion: {},
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      newImage: null
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.occasion = {}
        this.newImage = null
        this.$validator.reset()
      }
    },
    occasion: {
      handler () {
        if (this.occasion.image === null) {
          this.newImage = null
        }
      },
      deep: true
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        let result = await this.$validator.validateAll()
        if (result) {
          this.progress = true

          if (this.newImage != null) {
            this.occasion.image = await this.submitImages()
          }
          const obj = {
            ...this.occasion,
            state: true,
            uid: auth.currentUser.uid,
            createdAt: FieldValue.serverTimestamp()
          }
          // New occasion
          const doc = await db.collection('occasions').add({
            ...obj
          })
          obj.id = doc.id
          this.$emit('add', obj)
          this.$emit('closeSidebar')
          this.$vs.notify({
            color: 'success',
            title: 'Ocasión',
            text: 'Ocasión creada correctamente.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    setSlug () {
      this.occasion.slug = this.getSlug(this.occasion.name)
    },
    getSlug (name) {
      let text = (name).trim().split(' ').join('-').toLowerCase().normalize('NFD')
          .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2')
          .normalize()
      return text
    },
    /**
     * Update img
     * @param input
     */
    updateCurrImg (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.$set(this.occasion, 'image', e.target.result)
          this.newImage = input.target.files[0]
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    /**
     * Save image in firebase
     * @returns {Promise<unknown>}
     */
    submitImages () {
      return new Promise((resolve, reject) => {
        let imageName = Math.random().toString(36).substr(1, 20)
        let fileExtension = '.' + this.newImage.name.split('.').pop()
        let imageNameFinal = imageName + fileExtension
        let storageRef = storage.ref(`occasions/${imageNameFinal}`)
        storageRef.put(this.newImage)
            .then((snapshot) => {
              snapshot.ref.getDownloadURL().then((url) => {
                resolve(url)
              })
            })
            .catch((error) => {
              reject(error)
            })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
